import React, { useRef, useState } from "react";
import { CashRow } from "../../common/CashRow";
import { materialTableIcons } from "../../common/TableIcons";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import ArchiveIcon from "@mui/icons-material/Archive";
import RestoreIcon from "@mui/icons-material//Restore";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import MessageIcon from "@mui/icons-material/Message";
import { Button, Grid, Tooltip } from "@mui/material";
import { CashRowStatus } from "../../common/CashRowStatus";
import {
  formatDate,
  formatNumberAsDate,
  formatUSDAmount,
} from "../../util/Utils";
import CustomGroupRow from "../CustomGroupRow";
import { CashRowTableRow } from "../../common/CashRowTableRow";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CommentDialog, { CommentDialogResultEnum } from "../CommentDialog";
import ConfirmationDialog, {
  ConfirmationDialogResult,
} from "../ConfirmationDialog/Index";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: "red",
  },
  headerIcon: {
    marginLeft: "10px",
  },
  submissionComment: {
    marginLeft: "5px",
  },
  headerTextItem: {
    fontStyle: "italic",
    marginLeft: "10px",
  },
}));

type CashRowsListProps = {
  cashRows: CashRow[];
  cashRowStatuses: CashRowStatus[];
  postToCrd: any;
  changeRowStatus: any;
};

const CashRowList: React.FunctionComponent<CashRowsListProps> = ({
  ...props
}) => {
  const classes = useStyles();
  const [tableRows, setTableRows] = useState<CashRowTableRow[]>([]);
  const [postDialogOpen, setPostDialogOpen] = React.useState(false);
  const [postDialogMessage, setPostDialogMessage] = React.useState<string>("");
  const [rowsToPost, setRowsToPost] = React.useState<CashRow[] | null>(null);
  const [commentDialogConfig, setCommentDialogConfig] =
    useState<CommentDialogConfig>({
      open: false,
      data: undefined,
    });

  const tableRef = useRef<any>();

  React.useEffect(() => {
    const newTableRows: CashRowTableRow[] = [];
    const pendingStatus = props.cashRowStatuses.find(
      (crs) => crs.Value === "Pending"
    );
    props.cashRows.forEach((cr) => {
      newTableRows.push({
        cashRow: cr,
        isPending: cr.CashStatusId === pendingStatus?.Id,
        isSelected: cr.CashStatusId === pendingStatus?.Id,
      });
    });
    setTableRows(newTableRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cashRowStatuses]);

  const handlePostDialogClose = (
    result: ConfirmationDialogResult,
    rows: CashRow[]
  ) => {
    setPostDialogOpen(false);
    if (result === ConfirmationDialogResult.Yes) {
      handlePostRows(rows);
    }
  };

  async function handlePostRows(postRows: CashRow[]) {
    props.postToCrd(postRows);
  }

  const calcSelectedRowsTotal = () => {
    const onlySelected = tableRows.filter((r) => r.isSelected);
    const selectedTotal = onlySelected.reduce(
      (sum, current) => sum + current.cashRow.AMT_USD,
      0
    );
    return formatUSDAmount(selectedTotal, true);
  };

  const moveToPending = (row: CashRowTableRow) => {
    const pendingRow = props.cashRowStatuses.find(
      (crs) => crs.Value === "Pending"
    );
    if (pendingRow) {
      row.cashRow.CashStatusId = pendingRow.Id;
      props.changeRowStatus(row.cashRow);
    }
  };

  const moveToArchive = (row: CashRowTableRow) => {
    const archiveRow = props.cashRowStatuses.find(
      (crs) => crs.Value === "ArchivedPostBOD"
    );
    if (archiveRow) {
      row.cashRow.CashStatusId = archiveRow.Id;
      props.changeRowStatus(row.cashRow);
    }
  };

  const openCommentDialog = (row: CashRowTableRow) => {
    setCommentDialogConfig({
      open: true,
      data: row,
    });
  };
  const toggleSelectAll = (selected: boolean) => {
    const newTableRows: CashRowTableRow[] = [];
    newTableRows.push(...tableRows);
    newTableRows.forEach((r) => {
      if (r.isPending) {
        r.isSelected = selected;
      }
    });
    setTableRows(newTableRows);
  };

  const prepAndPost = () => {
    const postRows: CashRow[] = [];
    const onlySelected = tableRows.filter((r) => r.isSelected);
    onlySelected.forEach((r) => postRows.push(r.cashRow));

    const total = postRows.reduce((sum, current) => sum + current.AMT_USD, 0);

    setPostDialogMessage(
      `Post ${postRows.length} rows for a total of  ${formatUSDAmount(
        total,
        true
      )}?`
    );
    setPostDialogOpen(true);
    setRowsToPost(postRows);
  };

  const handleDialogClose = (
    result: CommentDialogResultEnum,
    data?: CashRowTableRow
  ) => {
    setCommentDialogConfig({
      open: false,
      data: undefined,
    });
    if (result === CommentDialogResultEnum.Ok) {
      const newTableRows: CashRowTableRow[] = [];
      newTableRows.push(...tableRows);
      const subjectRow = newTableRows.find(
        (tr) => tr.cashRow.Id === data?.cashRow.Id
      );
      if (subjectRow) {
        subjectRow.cashRow.SubmissionComment = data
          ? data.cashRow.SubmissionComment
          : "";
        setTableRows(newTableRows);
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "30px",
            paddingBottom: "10px",
          }}
        >
          <Button variant="contained" onClick={prepAndPost}>
            Post to CRD
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            tableRef={tableRef}
            options={{
              draggable: false,
              thirdSortClick: false,
              toolbar: true,
              search: false,
              grouping: true,
            }}
            components={{
              Toolbar: (props) => (
                <div>
                  <MTableToolbar {...props} detailPanel={() => {}} />
                  <div style={{ padding: "0px 10px" }}>
                    <Tooltip title="Select All Pending Rows">
                      <LibraryAddCheckIcon
                        className={classes.headerIcon}
                        onClick={() => {
                          toggleSelectAll(true);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="De-select All Pending Rows">
                      <CheckBoxOutlineBlankIcon
                        className={classes.headerIcon}
                        onClick={() => {
                          toggleSelectAll(false);
                        }}
                      />
                    </Tooltip>
                    <span className={classes.headerTextItem}>
                      Selected Rows:{" "}
                      {tableRows.filter((fr) => fr.isSelected).length}
                    </span>
                    <span className={classes.headerTextItem}>
                      Selected Total {calcSelectedRowsTotal()}
                    </span>
                  </div>
                </div>
              ),
              Groupbar: (props) => (
                <div
                  style={{
                    height: "0px",
                    paddingBottom: "10px",
                  }}
                ></div>
              ),
              GroupRow: (props) => (
                <CustomGroupRow
                  {...props}
                  onGroupSelect={(data: any[]) => {
                    const newTableRows: CashRowTableRow[] = [...tableRows];
                    data.forEach((dr) => {
                      const existingRow = newTableRows.find(
                        (tr) => tr.cashRow.Id === dr.cashRow.Id
                      );
                      if (existingRow && existingRow.isPending) {
                        existingRow.isSelected = !existingRow.isSelected;
                      }
                    });

                    setTableRows(newTableRows);
                  }}
                />
              ),
            }}
            localization={{ toolbar: { searchPlaceholder: "Search" } }}
            icons={materialTableIcons}
            columns={[
              {
                title: "Selected",
                field: "isSelected",
                width: "5%",
                render: (rowData) => {
                  if (rowData.isPending) {
                    return (
                      <span
                        onClick={() => {
                          console.log(rowData);
                          const newTableRows: CashRowTableRow[] = [
                            ...tableRows,
                          ];
                          const existingRow = newTableRows.find(
                            (tr) => tr.cashRow.Id === rowData.cashRow.Id
                          );
                          if (existingRow) {
                            existingRow.isSelected = !existingRow.isSelected;
                          }
                          setTableRows(newTableRows);
                        }}
                      >
                        {rowData.isSelected ? (
                          <CheckBoxIcon />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )}
                      </span>
                    );
                  } else {
                    return "";
                  }
                },
              },
              {
                title: "Account",
                field: "cashRow.ACCT_CD",
                defaultGroupOrder: 0,
              },
              {
                title: "Sub Type CD",
                field: "cashRow.SUB_TYPE_CD",
              },
              {
                title: "Amt",
                field: "cashRow.AMT",
                render: (rowData) => {
                  return formatUSDAmount(rowData.cashRow.AMT, false);
                },
              },
              {
                title: "Currency CD",
                field: "cashRow.CRRNCY_CD",
                width: "7%",
              },
              {
                title: "Amt USD",
                field: "cashRow.AMT_USD",
                render: (rowData) => {
                  return formatUSDAmount(rowData.cashRow.AMT_USD, true);
                },
              },

              {
                title: "Forecast Date",
                field: "cashRow.ForecastDate",
                width: "7%",
                render: (rowData) => {
                  return formatNumberAsDate(rowData.cashRow.ForecastDate);
                },
              },
              {
                title: "Amt Type",
                field: "cashRow.AMT_TYPE",
                width: "5%",
              },

              {
                title: "Transaction Time",
                field: "cashRow.TransactionTime",
                width: "8%",
                render: (rowData) => {
                  return formatDate(rowData.cashRow.TransactionTime, false);
                },
              },
              {
                title: "Status",
                field: "cashRow.CashStatusId",
                render: (rowData) => {
                  return (
                    <>
                      <span>
                        {
                          props.cashRowStatuses.find(
                            (crs) => crs.Id === rowData.cashRow.CashStatusId
                          )?.Value
                        }
                      </span>
                      {!rowData.isPending && (
                        <>
                          <Tooltip title="Move to Pending">
                            <RestoreIcon
                              className={classes.headerIcon}
                              onClick={() => moveToPending(rowData)}
                            />
                          </Tooltip>
                          <Tooltip title="Archive Record">
                            <ArchiveIcon
                              className={classes.headerIcon}
                              onClick={() => moveToArchive(rowData)}
                            />
                          </Tooltip>
                        </>
                      )}
                    </>
                  );
                },
              },
              {
                title: "Created",
                field: "cashRow.CreatedDateTime",
                width: "7%",
                render: (rowData) => {
                  return formatDate(rowData.cashRow.CreatedDateTime, false);
                },
              },
              {
                title: "Comment",
                field: "cashRow.SubmissionComment",
                render: (rowData) => {
                  return (
                    <>
                      <Tooltip title="Add/Edit Comment">
                        <MessageIcon
                          onClick={() => {
                            openCommentDialog(rowData);
                          }}
                        />
                      </Tooltip>
                      <em className={classes.submissionComment}>
                        {rowData.cashRow.SubmissionComment}
                      </em>
                    </>
                  );
                },
              },
            ]}
            data={tableRows}
            title="Cash Rows"
          ></MaterialTable>
        </Grid>
      </Grid>
      <CommentDialog
        open={commentDialogConfig.open}
        onClose={handleDialogClose}
        isYesNo={false}
        data={commentDialogConfig.data}
      />
      <ConfirmationDialog
        open={postDialogOpen}
        onClose={handlePostDialogClose}
        message={postDialogMessage}
        title="Post to CRD"
        isYesNo={true}
        data={rowsToPost}
      />
    </>
  );
};

export default CashRowList;

interface CommentDialogConfig {
  open: boolean;
  data?: CashRowTableRow;
}
