import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AppLayout from "../../components/AppLayout";

type LoginProps = {};
const LoginPage: React.FunctionComponent<LoginProps> = () => {
  return (
    <AppLayout>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            component="h3"
            variant="h3"
            align="center"
            color="text.primary"
            fontWeight={100}
            gutterBottom
          >
            Log in to Intraday Cash
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            Login using your existing Cbre / Clarion login account.
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button
              variant="contained"
              href={`/.auth/login/${"aad"}?post_login_redirect_uri=${`/`}`}
            >
              Log in
            </Button>
          </Stack>
        </Container>
      </Box>
    </AppLayout>
  );
};

export default LoginPage;
