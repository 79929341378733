import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { UserInfo } from "../../common/UserInfo";
import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Box, Divider, Hidden, Theme} from "@mui/material";
import NavBar from "./NavBar";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      overvlow: "unset"
    },
    search: {
      position: "relative",
      top: 0,
      left: 0,
      alignItems: "center",
      justifyContent: "center",
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    searchIcon: {
      position: "relative",
      top: 0,
      left: 0,
      display: "flex",

      padding: theme.spacing(0, 1),
      height: "100%",
      pointerEvents: "none",
      alignItems: "center",
      justifyContent: "center",
    },
    clearIcon: {
      display: "flex",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      color: "inherit",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    fabProgress: {
      color: theme.palette.grey[50],
      position: "absolute",
      top: -3,
      left: 4,
      zIndex: 1000,
    },
    menuIcon: {
      color: theme.palette.text.secondary,
      height: 20,
      marginRight: 8,
    },
    abRoot: {
      backgroundColor: "#3f51b5",
      color: "#FFFFFF",
    },
    abStatic: {},
  })
);

type HeaderProps = {
  userInfo: UserInfo | undefined;
};

const Header: React.FunctionComponent<HeaderProps> = ({ userInfo }) => {
   const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    console.log("handleMobileMenuOpen");
    setMobileMoreAnchorEl(event.currentTarget);
  };

 
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <AccountCircle className={classes.menuIcon} /> Profile        
      </MenuItem>
      <Divider light />
      <MenuItem onClick={() => (window.location.href = "/.auth/logout")}>
        <LogoutOutlinedIcon className={classes.menuIcon} />        
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {renderMenu}
    </Menu>
  );

  return (
    <div>
      <AppBar 
        position="static"
        color="primary"
        style={{ backgroundColor: "#003F2D", color: "#FFFFFF" }}
      >
        <Toolbar color="primary">
          <Typography className={classes.title} variant="h6" noWrap>          
            Intraday Cash
          </Typography>
            <NavBar userInfo={userInfo} />

          <div className={classes.grow} />
          
          <Hidden only={["xs", "sm"]}>
            <Box component="div" className={classes.sectionDesktop}>            
              {userInfo && (userInfo.userDetails || "")}
            </Box>
          </Hidden>
          <div
            title={userInfo && (userInfo.userDetails || "")}
            className={classes.sectionDesktop}
          >
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              size="large"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>          
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              size="large"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

export default Header;
