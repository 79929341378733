import { CashRow } from "../common/CashRow";
import { CashRowStatus } from "../common/CashRowStatus";
import { CrdPostResult } from "../common/CrdPostResult";
import { FxRow } from "../common/FxRow";
import { MarketValueRow } from "../common/MarketValueRow";
import { ApiService } from "../services/ApiService";

export class IntradayStore {
  private _apiService: ApiService;

  constructor(apiService: ApiService) {
    this._apiService = apiService;
  } 

  public async getCashRows(): Promise<CashRow[]> {
    const response = await this._apiService.get("cashrows-get", {});
    return response.data;
  }
  public async getCashRowStatuses(): Promise<CashRowStatus[]> {
    const response = await this._apiService.get("cashrowstatuses-get", {});
    return response.data;
  }
  public async getFxRows(): Promise<FxRow[]> {
    const response = await this._apiService.get("fxrows-get", {});
    return response.data;
  }

  public async getMarketValeRows(): Promise<MarketValueRow[]> {
    const response = await this._apiService.get("marketvaluerows-get", {});
    return response.data;
  }
  public async changeCashRowStatus(row: CashRow): Promise<CashRow> {
    const response = await this._apiService.post("cashrow-changestatus", {data: row});
    return response.data;
  }

  public async postToCrd(rows: CashRow[]): Promise<CrdPostResult> {
    const response = await this._apiService.post("cashrows-post", {
      data: rows,
    });
    return response.data;
  }
  public async postReportTrigger(postResult: CrdPostResult): Promise<any[]> {
    const response = await this._apiService.post("triggerPostReport-post", {data: postResult});
    return response.data;
  }
}
