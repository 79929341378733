import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

export enum ConfirmationDialogResult {
  Yes,
  No,
  Ok,
  Cancel,
}

type ConfirmationDialogProps = {
  onClose: any;
  open: boolean;
  message: string;
  title: string;
  isYesNo: boolean;
  data?: any;
};

const ConfirmationDialog: React.FunctionComponent<ConfirmationDialogProps> = ({
  onClose,
  open,
  message,
  title,
  isYesNo,
  data,
  ...other
}) => {
  const handleCancel = () => {
    const result: ConfirmationDialogResult = isYesNo
      ? ConfirmationDialogResult.No
      : ConfirmationDialogResult.Cancel;
    onClose(result);
  };

  const handleOk = () => {
    const result: ConfirmationDialogResult = isYesNo
      ? ConfirmationDialogResult.Yes
      : ConfirmationDialogResult.Ok;
    onClose(result, data);
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <p>
          <em>{message}</em>
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            handleCancel();
          }}
        >
          {isYesNo ? "No" : "Cancel"}
        </Button>
        <Button
          onClick={() => {
            handleOk();
          }}
        >
          {isYesNo ? "Yes" : "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
