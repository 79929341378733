import { Alert, Backdrop, Button, CircularProgress, IconButton, Snackbar } from "@mui/material";
import React from "react";
import { useState } from "react";
import { AppContext } from "../../AppContext";
import { CashRow } from "../../common/CashRow";
import { CashRowStatus } from "../../common/CashRowStatus";
import AppLayout from "../../components/AppLayout";
import CashRowList from "../../components/CashRowsList";
import CloseIcon from '@mui/icons-material/Close';
// import { CrdPostResult } from "../../common/CrdPostResult";

type HomeProps = {};
const Home: React.FunctionComponent<HomeProps> = () => {
  const appContext = React.useContext(AppContext);
  const [isMetaDataLoaded, setIsMetaDataLoaded] = useState<Boolean>(false);
  const [cashRows, setCashRows] = useState<CashRow[]>([]);
  const [showSpinner, setShowSpinner] = React.useState(false);
  // const [postResult, setPostresult] = React.useState<CrdPostResult|undefined>(undefined);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [cashRowStatuses, setCashRowStatuses] = useState<CashRowStatus[]>([]);

  const handlePostToCrd = async (rows: CashRow[]) => {
    

    setShowSpinner(true);
    appContext.intradayStore
      .postToCrd(rows)
      .then((result) => {
        
        //success...now trigger the report.
        appContext.intradayStore.postReportTrigger(result).then((result) => {

          setShowSpinner(false);
          setIsMetaDataLoaded(false);
          if(result.length > 0){
           setSnackbarOpen(true); 
          }
        }).catch((error) => {
          const msg = `Error posting the trigger for the report, but the cash rows should have posted to CRD. ${error}`;
          console.log(msg);          
          setShowSpinner(false);
        })
      })
      .catch((error) => {

        console.log("Error in post to crd");
        console.log(error);
        setShowSpinner(false);
        
      });
  };

  const handleSpinnerClose = () => {
    setShowSpinner(false);
  };

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if(reason === 'clickaway'){
      return;
    }
    setSnackbarOpen(false);
  }
  const handleCashRowStatusChange = async (row: CashRow) => {
    console.log("post cash row change", row);
    setShowSpinner(true);
    appContext.intradayStore
      .changeCashRowStatus(row)
      .then((result) => {
        setShowSpinner(false);
        setIsMetaDataLoaded(false);
      })
      .catch((error) => {
        console.log("Error from changing status row");
        console.log(error);
        setShowSpinner(false);
      });
  };
  React.useEffect(() => {
    const fetchData = async () => {
      if (!isMetaDataLoaded) {
        try {
          let [
            cashRowsResp,
            cashRowStatusesResp,
          ] = await Promise.all([
            appContext.intradayStore.getCashRows(),
            appContext.intradayStore.getCashRowStatuses(),
          ]);
          console.log("async func resp", cashRowStatusesResp);

          setCashRows(cashRowsResp);
          setCashRowStatuses(cashRowStatusesResp);

          setIsMetaDataLoaded(true);
        } catch (fetchError) {
          console.log(fetchError);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMetaDataLoaded]);


  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleSnackbarClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  return (
    <AppLayout>
      <CashRowList
        cashRows={cashRows}
        cashRowStatuses={cashRowStatuses}
        postToCrd={handlePostToCrd}
        changeRowStatus={handleCashRowStatusChange}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
        onClick={handleSpinnerClose}
      >
        <CircularProgress color="inherit" size="5rem" thickness={1} />
      </Backdrop>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{horizontal:"center", vertical:"top"}}
        action={action}
      >
        <Alert severity="error">
        Some rows failed to post to CRD.  You can try to resubmit them.
        </Alert>
      </Snackbar>
    </AppLayout>
  );
};

export default Home;
