import { UserInfo } from "../common/UserInfo";
import { UserRole } from "../common/UserRole";
import { ApiService } from "../services/ApiService";


export class IntradayUserStore {
    private _apiService: ApiService;
  
    constructor(apiService: ApiService) {
      this._apiService = apiService;
    }

    public async getUserInfo(): Promise<UserInfo | undefined> {
      try {
        const response = await fetch("/.auth/me");     
        const payload = await response.json();
        const { clientPrincipal } = payload;
        const appRoles = await this.getAllUserRoles(clientPrincipal.userDetails);


        // return clientPrincipal;
        const user : UserInfo = {
          identityProvider: clientPrincipal.identityProvider,
          userDetails : clientPrincipal.userDetails,
          userId: clientPrincipal.userId,
          userRoles: clientPrincipal.userRoles,
          applicationRoles: appRoles
        };
        return user;
      } catch (error) {
        console.error("No user profile could be found.");
        return undefined;
      }
    }

    public async getAllUserRoles(username: string): Promise<UserRole[]> {
        const response = await this._apiService.get("userroles-get", {
        });
        
        return response.data;
      }
}