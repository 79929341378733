import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { UserInfo } from "../../common/UserInfo";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  navBar: {},
  navbarUL: {
    listStyle: "none",
    display: "inline",
  },
  navBarLI: {
    display: "inline",
    marginLeft: "10px",
  },
  navLinks: {
    color: "#FFFFFF",
    textDecoration: "none",
  },
}));

type NavBarProps = {
  userInfo: UserInfo | undefined;
};

const NavBar: React.FunctionComponent<NavBarProps> = ({ userInfo }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.navBar}>
        <ul className={classes.navbarUL}></ul>
      </div>
    </>
  );
};

export default NavBar;
