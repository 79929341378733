import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';

export class ApiService {
  private _webFullUrl: string | undefined;
  private _axios: AxiosInstance;
  private _loginUrl = '/login.html';
 
  constructor(webFullUrl?: string, relativePath?: string) {
    if (webFullUrl == null) {
      webFullUrl = window.location.href;
    }

    this._webFullUrl = webFullUrl;

    if (relativePath && !relativePath.endsWith('/')) {
      relativePath = `${relativePath}/`;
    }

    this._axios = axios.create({
      baseURL: `api`
    });
  }

  get webFullUrl(): string | undefined {
    return this._webFullUrl;
  }

  async get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    config = {
      ...config,
      method: 'GET',
      url: url,
      params: {
        ...(config && config.params)
      }
    };
    try {
      const res = await this._axios.request(config);
      // detect an 302 redirect to login page.
      if (res && res.request && res.request.responseURL && res.request.responseURL.indexOf('/login.html') >= 0) {
        window.location.href = this._loginUrl;
      }
      return res;
    } catch (error: any) {
      if (error.response.status && ((error.response.status >= 300 && error.response.status <= 399) || error.response.status === 401)) {
        if (error.response && error.response.data && error.response.data.location) {
          window.location = error.response.data.location;
        } else {
          window.location.href = this._loginUrl;
        }
      }
      throw error;
    }
  }

  async delete(url: string, config?: AxiosRequestConfig) {
    config = {
      ...config,
      method: 'DELETE',
      url: url,
      params: {
        ...(config && config.params)
      }
    };
    try {
      const res = await this._axios.request(config);
      // detect an 302 redirect to login page.
      if (res && res.request && res.request.responseURL && res.request.responseURL.indexOf('/login.html') >= 0) {
        window.location.href = this._loginUrl;
      }
      return res;
    } catch (error: any) {
      if (error.response.status && ((error.response.status >= 300 && error.response.status <= 399) || error.response.status === 401)) {
        if (error.response && error.response.data && error.response.data.location) {
          window.location = error.response.data.location;
        } else {
          window.location.href = this._loginUrl;
        }
      }
      throw error;
    }
  }

  async post(url: string, config?: AxiosRequestConfig) {
    config = {
      ...config,
      method: 'POST',
      url: url,
      params: {
        ...(config && config.params)
      }
    };
    try {
      const res = await this._axios.request(config);
      // detect an 302 redirect to login page.
      if (res && res.request && res.request.responseURL && res.request.responseURL.indexOf('/login.html') >= 0) {
        window.location.href = this._loginUrl;
      }
      return res;
    } catch (error: any) {
      if (error.response.status && ((error.response.status >= 300 && error.response.status <= 399) || error.response.status === 401)) {
        if (error.response && error.response.data && error.response.data.location) {
          window.location = error.response.data.location;
        } else {
          window.location.href = this._loginUrl;
        }
      }
      throw error;
    }
  }

  async put(scriptOrFilePath: string, config?: AxiosRequestConfig) {
    config = {
      ...config,
      method: 'POST',
      params: {
        ...(config && config.params)
      },
      headers: {
        ...(config && config.headers),
        'X-HTTP-Method': 'PUT'
      }
    };
    try {
      const res = await this._axios.request(config);
      // detect an 302 redirect to login page.
      if (res && res.request && res.request.responseURL && res.request.responseURL.indexOf('/login.html') >= 0) {
        window.location.href = this._loginUrl;
      }
      return res;
    } catch (error: any) {
      if (error.response.status && ((error.response.status >= 300 && error.response.status <= 399) || error.response.status === 401)) {
        if (error.response && error.response.data && error.response.data.location) {
          window.location = error.response.data.location;
        } else {
          window.location.href = this._loginUrl;
        }
      }
      throw error;
    }
  }

  request(config: AxiosRequestConfig) {
    return this._axios.request(config);
  }
}
