import React from "react";
import { IntradayStore } from "./stores";
import { ApiService } from "./services/ApiService";
import { IntradayUserStore } from "./stores/IntradayUserStore";

let webRelativeUrl = "";

// development
if (process.env.NODE_ENV === "production") {
  webRelativeUrl = "../";
}

export interface AppStore {
  apiService: ApiService;
  intradayStore: IntradayStore;
  intradayUserStore: IntradayUserStore;
}

const apiService = new ApiService(webRelativeUrl, "/api/");

export const defaultAppStore: AppStore = {
  apiService: apiService,
  intradayStore: new IntradayStore(apiService),
  intradayUserStore: new IntradayUserStore(apiService)  
};

export const AppContext = React.createContext(defaultAppStore);