import { UserInfo } from "../../common/UserInfo";
import LoginPage from "../../pages/login";
import UnauthorizedPage from "../../pages/unauthorized";
import { authorizeUser } from "../../util/Utils";

type ProtectedRouteProps = {
  userInfo: UserInfo | undefined;
  children: any;
  adminOnly?: boolean;
};

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
  children,
  userInfo,
  adminOnly,
}) => {
  const isAdmin = authorizeUser(
    process.env.REACT_APP_ADMIN_ROLE ?? "Admin",
    userInfo
  );
  if (userInfo && (!adminOnly || isAdmin)) {
    return <>{children}</>;
  } else {
    if (userInfo) {
      return <UnauthorizedPage />;
    } else {
      return <LoginPage />;
    }
  }
};

export default ProtectedRoute;
