import { UserInfo } from "../common/UserInfo";

export const authorizeUser = (role: string, user?: UserInfo ): boolean => {
    //Placeholder in case separate roles for this app are ever defined.
    return true;
  }

  export function formatDate(dateString: string, includeTime: boolean) {
    if (dateString) {
      if (includeTime) {
        return new Date(dateString).toLocaleString('en-US');
      } else {
        return new Date(dateString).toLocaleDateString('en-US');
      }
    }
    return '';
  }

  export function formatNumberAsDate(rawDate:number): string {
    const rawDateStr = rawDate.toString();
    if(rawDateStr.length !== 8){
      //if not a full date, just pass back the string value
      return rawDateStr;
    }else{
      const year = rawDateStr.substring(0,4);
      const month = rawDateStr.substring(4,6);
      const day = rawDateStr.substring(6,9);
      const newDateStr =  `${month}/${day}/${year}`;
      return formatDate(newDateStr,false);

    }
  }

  export function formatUSDAmount(amount:number, showDollarSign: boolean){
    const usIsNegative = 0 > amount;
    const dollarUSLocale = Intl.NumberFormat('en-US');
    return `${usIsNegative ? '-': ''}${showDollarSign ? '$': ''}${dollarUSLocale.format(Math.abs((Number(amount.toFixed(2)))))}`;
  }
  