import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { CashRowTableRow } from "../../common/CashRowTableRow";
import { formatUSDAmount } from "../../util/Utils";

export enum CommentDialogResultEnum {
  Yes,
  No,
  Ok,
  Cancel,
}

type CommentDialogProps = {
  onClose: any;
  open: boolean;
  isYesNo: boolean;
  data?: CashRowTableRow;
  };

const CommentDialog: React.FunctionComponent<CommentDialogProps> = ({
  onClose,
  open,
  isYesNo,
  data,
  ...other
}) => {

  let entryContent: string = '';
  
  const addOrEdit = data?.cashRow.SubmissionComment ? 'Edit Comment' : 'Add Comment';

  const handleCancel = () => {
    const result: CommentDialogResultEnum = isYesNo ? CommentDialogResultEnum.No : CommentDialogResultEnum.Cancel;      
    onClose(result);
  };

  const handleTextChange = (evt: any) => {
    entryContent = evt.target.value;

  }
  const handleOk = () => {
    const result = isYesNo ? CommentDialogResultEnum.No : CommentDialogResultEnum.Cancel;
    if(data){
      data.cashRow.SubmissionComment = entryContent;
    }
    onClose(result,data);
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle>{addOrEdit}</DialogTitle>
      <DialogContent>
        <DialogContentText>{`Add a comment for ${data ? data.cashRow.ACCT_CD: ''} - ${formatUSDAmount(data ? data.cashRow.AMT_USD : 0,true)}:`}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label=""          
          fullWidth
          multiline
          defaultValue={data ? data.cashRow.SubmissionComment : ''}
          variant="standard"
          onChange={handleTextChange}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            handleCancel();
          }}
        >
          {isYesNo ? "No" : "Cancel"}
        </Button>
        <Button
        variant="contained"
          onClick={() => {
            handleOk();
          }}
        >
          {isYesNo ? "Yes" : "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentDialog;
