import { createTheme } from "@mui/material/styles";
import CalibreWoff2 from "../assets/fonts/calibre/Web/calibre-web-regular.woff2";
import FinancierWoff2 from "../assets/fonts/financier_display/Web/financier-display-web-regular.woff2";

const calibre = {
  fontFamily: "Calibre",
  src: `
    local('calibre'),
    local('calibre-web-regular'),
    url(${CalibreWoff2}) format('woff2')
  `,
};

const financier = {
  fontFamily: "Financier",
  src: `
    local('financier'),
    local('financier-display-web-regular'),
    url(${FinancierWoff2}) format('woff2')
  `,
};

const theme = createTheme({
  palette: {
    background: {
      default: '#CAD1D3',
      // paper: '#f4f5f6'
    },
    /* Green */
    primary: {
      light: "#17E88F",
      main: "#003F2D",
      dark: "#012A2D",
    },
    /* Dark green */
    secondary: {
      light: "#17E88F",
      main: "#003F2D",
      dark: "#012A2D",
    },
  },
  typography: {
    fontFamily: [
      "Calibre",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      `sans-serif`,
    ].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": [calibre, financier],
      },
    }
  },
});

export default theme;
