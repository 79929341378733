import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { Tooltip } from "@mui/material";
import { formatUSDAmount } from "../../util/Utils";

interface CustomGroupRowDetailProps {
  columnTitle: string;
  columnValue: string;
  separator: string;
  groupData: any[];
  onGroupSelect: any;
}

const CustomGroupRowDetail: React.FC<CustomGroupRowDetailProps> = ({
  ...props
}) => {
  const groupTotal: number = props.groupData.reduce(
    (sum, current) => sum + current.cashRow.AMT_USD,
    0
  );
  const onlySelected = props.groupData.filter((r) => r.isSelected);
  const selectedTotal = onlySelected.reduce(
    (sum, current) => sum + current.cashRow.AMT_USD,
    0
  );

  return (
    <>
      <Tooltip title="Toggle selection of pending rows in group">
        <LibraryAddCheckIcon
          onClick={() => props.onGroupSelect(props.groupData)}
        />
      </Tooltip>
      <b>
        {props.columnTitle}
        {props.separator}
        {props.columnValue}
      </b>

      <b style={{ marginLeft: 25 }}>
        Total
        {props.separator}
        {formatUSDAmount(groupTotal, true)}
      </b>
      <b style={{ marginLeft: 25 }}>
        Selected
        {props.separator}
        {formatUSDAmount(selectedTotal, true)}
      </b>
    </>
  );
};

export default CustomGroupRowDetail;
