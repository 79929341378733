import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import AppErrorBoundary from "../../components/AppErrorBoundary";
import AppSuspense from "../../components/AppSuspense";

interface ContentViewProps {
  children?: ReactNode;
}

const ContentView: React.FC<ContentViewProps> = (props) => {
  return (
    <div className="scrollbar-container">
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        className="main-content-view"
      >
        <AppSuspense>
          <AppErrorBoundary>{props.children}</AppErrorBoundary>
        </AppSuspense>
      </Box>
    </div>
  );
};

export default ContentView;
